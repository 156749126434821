const allowedDomains = ['poweredbypercent.com', 'goodstack.io']
const fallbackDomain = 'poweredbypercent.com'

export const getPrimaryDomain = (hostname: string): string => {
  const parts = hostname.split('.')

  if (parts.length < 2) {
    return hostname
  }

  return parts.slice(-2).join('.')
}

/**
 * Gets domain from window.location.hostname if it is one of poweredbypercent.com, goodstack.io. Else just returns poweredbypercent.com
 *
 * @returns {string} - The API domain if it is allowed, otherwise the fallback domain.
 */
export const getApiDomainFromHostname = () => {
  const apiDomain = getPrimaryDomain(window.location.hostname)

  if (!allowedDomains.includes(apiDomain)) {
    return fallbackDomain
  }

  return apiDomain
}

/**
 * Performs a string mutation to assign original url (eg https://api.example.com) to a new api domain (eg https://api.newdomain.com)
 * @param originalUrl - The original url to be mutated
 * @param apiDomain - The new api hostname to assign to the original url
 * @returns - The mutated url
 */
export const reassignUrlDomain = (originalUrl: string, apiDomain: string) => {
  if (!originalUrl.startsWith('http')) {
    originalUrl = `https://${originalUrl}`
  }

  const url = new URL(originalUrl)

  const hostnameParts = url.hostname.split('.')

  const newHostname = [
    ...hostnameParts.slice(0, -2),
    ...apiDomain.split('.'),
  ].join('.')

  url.hostname = newHostname
  const urlString = url.toString()

  if (url.toString().endsWith('/')) {
    return urlString.slice(0, -1)
  }

  return urlString
}
