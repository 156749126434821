import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { BreadcrumbsContextController } from './breadcrumbsContext/breadcrumbsContextController/BreadcrumbsContextController'
import { Routes } from './Routes'
import { TopBar } from './topbar/TopBar'
import { NoPrivilegeAccessView } from '@percent/partner-dashboard/common/components'
import { usePermissionCheck } from '@percent/partner-dashboard/common/hooks/useAllow/usePermissionCheck'
import { Navigation } from '@percent/lemonade'
import { environments } from '@percent/partner-dashboard/constants/environments'
import { environment } from '@percent/partner-dashboard/config'
import { ReactComponent as PartnerSandboxIcon } from '@percent/partner-dashboard/common/assets/images/partner-sandbox-icon.svg'
import { ReactComponent as PartnerDashboardIcon } from '@percent/partner-dashboard/common/assets/images/partner-dashboard-icon.svg'
import { useAuthState, useFeatureFlag } from '@percent/partner-dashboard/common/hooks'
import { selectScopes } from '@percent/partner-dashboard/context/auth'
import { PartnerScopes } from '@percent/partner-dashboard/constants/scopes'
import { NavItemProps } from 'libs/shared/ui-lemonade/src/components/navigation/navigation.types'
import { NavConfigItem, GetPDNavItemsParams, CreateNavItemParams } from './Layout.types'

export const drawerWidth = '240px'

function createNavItem({ condition, icon, label, href, children, pathname }: CreateNavItemParams) {
  if (!condition) return null

  return {
    icon,
    label,
    href,
    isActive: pathname.includes(href.includes('?') ? href.split(/\?/)[0] : href.split(/\/+/).filter(Boolean)[0]),
    ...(children
      ? {
          children: children.map(child => ({ ...child, isActive: child.pathname.includes(child.href.split('?')[0]) }))
        }
      : {})
  }
}

function getPDNavItems({ pathname, scopes, featureFlags, userCan, t }: GetPDNavItemsParams) {
  const navConfig: NavConfigItem[] = [
    {
      icon: 'menu-dashboard',
      label: t('menu.dashboard'),
      href: '/dashboard',
      condition: true
    },
    {
      icon: 'menu-donations',
      label: t('menu.donations'),
      href: '/donations',
      condition: (scopes[PartnerScopes.Donation] || scopes[PartnerScopes.DonationSession]) && userCan('viewDonations')
    },
    {
      icon: 'menu-categories',
      label: t('menu.categories'),
      href: '/categories',
      condition: scopes[PartnerScopes.Organisation]
    },
    {
      icon: 'menu-nonprofits',
      label: t('menu.nonprofits'),
      href: '/nonprofits',
      condition: scopes[PartnerScopes.Donation] && scopes[PartnerScopes.Organisation]
    },
    {
      icon: 'menu-workplace-giving',
      label: t('menu.workplaceGiving'),
      href: '/workplace-giving/employees',
      condition:
        scopes[PartnerScopes.workplaceGiving] &&
        featureFlags.workplaceGivingFeatureFlag === true &&
        (userCan('manageOwners') || userCan('manageAdmins')),
      children: [
        {
          label: 'Employees',
          href: '/workplace-giving/employees'
        }
      ]
    },
    {
      icon: 'menu-verifications',
      label: t('menu.verifications'),
      href: '/verifications',
      condition: scopes[PartnerScopes.ValidationInvite] || scopes[PartnerScopes.SingleValidationSubmissionApi]
    },
    {
      icon: 'menu-keys',
      label: t('menu.keys'),
      href: '/keys',
      condition: userCan('viewApiKeys')
    }
  ]

  return navConfig
    .map(item =>
      createNavItem({
        ...item,
        pathname,
        children: item.children ? item.children.map(child => ({ ...child, pathname })) : undefined
      })
    )
    .filter((item): item is NavItemProps => !!item)
}

export function Layout() {
  const [path, setPath] = useState<string | undefined | null>(undefined)
  const { t } = useTranslation()
  const { workplaceGivingFeatureFlag } = useFeatureFlag()
  const { userCan } = usePermissionCheck()

  const { pathname } = useLocation()
  const { partner } = useAuthState()
  const scopes = selectScopes(partner?.scopes)

  if (userCan('viewDashboard')) {
    return (
      <BreadcrumbsContextController path={path} setPath={setPath}>
        <TopBar />
        <Navigation
          logo={
            <>
              {environment === environments.SANDBOX ? (
                <PartnerSandboxIcon width={32} />
              ) : (
                <PartnerDashboardIcon width={32} />
              )}
              <p>{t('menu.partnerDashboard')}</p>
            </>
          }
          items={getPDNavItems({
            pathname,
            scopes,
            featureFlags: {
              workplaceGivingFeatureFlag
            },
            userCan,
            t
          })}
        />
        <div style={{ marginLeft: drawerWidth, padding: 40 }}>
          <Routes />
        </div>
      </BreadcrumbsContextController>
    )
  }

  return <NoPrivilegeAccessView />
}
