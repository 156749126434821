import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { NavItem } from './nav-item'
import {
  NavigationProps,
  NavItemProps,
  NavItemWithChildrenProps,
} from './navigation.types'

const StyledNavigation = styled.nav`
  ${({ theme }) => css`
    width: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: ${theme.sizes.s};
    background: white;
    height: 100%;
    display: flex;
    outline: 0;
    z-index: 1200;
    position: fixed;
    overflow-y: auto;
    flex-direction: column;
    top: 0;
    box-shadow: 0px 2px 16px rgba(5, 0, 56, 0.08);
  `}
`

const LogoSection = styled.div`
  ${({ theme }) => css`
    padding: ${theme.sizes[3]} ${theme.sizes[6]};
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 500;
    font-size: 14px;
  `}
`

const MainArea = styled.div`
  ${({ theme }) => css`
    flex: 1;
    padding: 0 ${theme.sizes.l};
    display: flex;
    flex-direction: column;
    gap: ${theme.sizes[7]};
    overflow-y: auto;
    margin-top: ${theme.sizes.l};
  `}
`

const StyledNavLink = styled(NavLink)`
  text-decoration: none;

  &:focus {
    outline: none;
  }
`

const SubItemContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.sizes.xs};
    margin-top: ${theme.sizes.s};
    padding-left: ${theme.sizes.l};
  `}
`

const Footer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.sizes.s};
    padding: ${theme.sizes.l};
    border-top: 2px solid ${theme.colors.gray90};
  `}
`

function isNestedItem(
  item: NavItemProps | NavItemWithChildrenProps
): item is NavItemWithChildrenProps {
  return 'children' in item
}

export const Navigation = ({
  logo,
  items = [],
  secondaryItems,
}: NavigationProps) => {
  const [openSection, setOpenSection] = useState('')

  const toggleSection = (key: string) => {
    if (openSection === key) {
      return setOpenSection('')
    }

    return setOpenSection(key)
  }

  return (
    <StyledNavigation>
      <LogoSection>{logo}</LogoSection>

      <MainArea>
        {items.map((item) => {
          if (isNestedItem(item)) {
            return (
              <div key={`section-${item.label}`}>
                <StyledNavLink key={item.label} to={item.href}>
                  <NavItem
                    icon={item.icon}
                    label={item.label}
                    hasChevron
                    onClick={() => toggleSection(item.label)}
                    isActive={item.isActive}
                  />
                </StyledNavLink>

                {item.isActive ? (
                  <SubItemContainer>
                    {item.children.map((child) => (
                      <StyledNavLink key={child.label} to={child.href}>
                        <NavItem
                          label={child.label}
                          isActive={child.isActive}
                          isSubItem
                        />
                      </StyledNavLink>
                    ))}
                  </SubItemContainer>
                ) : null}
              </div>
            )
          }

          return (
            <StyledNavLink to={item.href} key={item.label}>
              <NavItem
                icon={item.icon}
                label={item.label}
                isActive={item.isActive}
              />
            </StyledNavLink>
          )
        })}
      </MainArea>

      {secondaryItems && (
        <Footer>
          {secondaryItems &&
            secondaryItems.map((item) => (
              <StyledNavLink key={item.label} to={item.href}>
                <NavItem label={item.label} isActive={item.isActive} />
              </StyledNavLink>
            ))}
        </Footer>
      )}
    </StyledNavigation>
  )
}
